import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
// import { notification } from 'antd';
import { notification } from '../components/static-func/notification';

const unauthorizedCode = [401, 403]

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000 * 100
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
	
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
	}

  	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})

	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return {data: response.data, responseHeaders: response.headers};
}, (error) => {

	let notificationParam = {
		message: `Error`
	}
	if (error?.response === undefined) {
		notificationParam.description = error.toString();
		notification.error(notificationParam)
		return Promise.reject(error);
	}

	if (error?.response?.status === 403) {
		notificationParam.message = error.response.statusText;

		if (typeof error.response.data?.error === 'string') {
			notificationParam.description = error.response.data?.error;
		}
		notification.error(notificationParam)

		return {data: {}};
	}

	if (error?.response?.status === 400) {
		notificationParam.message = error.response.statusText;

		if (typeof error.response.data?.error === 'string') {
			notificationParam.description = error.response.data?.error;
		}
		if (Array.isArray(error.response.data?.error)) {

			notificationParam.description = <div>{error.response.data.error.map((v, i) => {
				if (typeof v === 'string') {
					return <p key={i}>{v}</p>;
				}
				return null;
			})}</div>;
		}

    if (Object.prototype.toString.call(error.response.data?.error) === '[object Object]') {
      let {error:errorObj} = error.response.data;
      let errorList = [];
      for (let key in errorObj) {
        if (errorObj.hasOwnProperty(key)) {
          errorList.push(<p key={key}>{key}: {errorObj[key]}</p>)
        }
      }
      notificationParam.description = <div>{errorList}</div>;
    }

	}

	// Remove token and redirect 
	if (unauthorizedCode.includes(error.response.status)) {
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN)

		store.dispatch(signOutSuccess())
	}

	if (error.response.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}
	
	if (error.response.status === 508) {
		notificationParam.message = 'Time Out'
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service