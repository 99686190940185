import antdEnUS from 'antd/es/locale/en_US';
import antdUkUA from "antd/es/locale/uk_UA";
import en from './locales/en_US.json';
import ua from './locales/uk_UA.json';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {THEME_CONFIG} from 'configs/AppConfig';

export const resources = {
  en: {
    translation: en,
    antd: antdEnUS
  },
  ua: {
    translation: ua,
    antd: antdUkUA
  },

}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: THEME_CONFIG.locale,
  lng: THEME_CONFIG.locale,
  interpolation: {
    escapeValue: false
  }
})

export const i18nText = (key, fallback) => {
  return i18n.t(key, fallback);
};

export default i18n;