import React from 'react'
import {AUTH_PREFIX_PATH, APP_PREFIX_PATH} from 'configs/AppConfig'

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('views/auth-views/authentication/login')),
  },
  {
    key: 'register',
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() => import('views/auth-views/authentication/register')),
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
  }
]

export const protectedRoutes = [
  {
    key: 'channels.all',
    path: `${APP_PREFIX_PATH}/channels/*`,

    component: React.lazy(() => import('views/app-views/channels')),
  },

  {
    key: 'telegram-users',
    path: `${APP_PREFIX_PATH}/telegram-users`,

    component: React.lazy(() => import('views/app-views/telegram-users')),
  },

  {
    key: 'telegram-user-messages',
    path: `${APP_PREFIX_PATH}/telegram-users/:userId`,

    component: React.lazy(() => import('views/app-views/telegram-user-messages')),
  },

  {
    key: 'search-with-filters',
    path: `${APP_PREFIX_PATH}/search`,

    component: React.lazy(() => import('views/app-views/search-with-filters')),
  },
]